/*================ Images ================*/

/**
 * Spacing
 */
.image {
  margin-bottom: 1.2em; // base line-height = 1.2

  @include media-query($large-up) {
    @include m-b-spacer-($spacer-lg);
  }
}

/**
 * Image caption
 */
.image__caption {
  @include type--small;
  @include m-t-spacer-($spacer-sm);
}

/*================ Site footer ================*/

/**
 * Spacing
 */
.site-footer {
  @include p-t-spacer-($spacer-lg);
  @include p-b-spacer-($spacer-md);

  @include media-query($large-up) {
    @include p-t-spacer-($spacer-xl);
  }
}

.site-footer .site-name {
  @include m-b-spacer-($spacer-lg);

  @include media-query($large-up) {
    margin-bottom: 0;
  }
}

/**
 * Border
 */
.site-footer {
  @include border-top;
}

/**
 * Site name formatting
 */
.site-footer .site-name a {
  @extend .link-no-underline;
}

/**
 * Dos credit
 */
.site-footer__credit {
  @include m-t-spacer-($spacer-lg);
  @include type--small;
}

/**
 * Contact info
 */
.site-footer__contact {
  @include m-t-spacer-($spacer-lg);
  @include type--small;
}

// -----------------------------------------------------------------------------
// This file defines how the colors will be used within the design system.
// -----------------------------------------------------------------------------

// Usage variables (application-focused)

// Backgrounds
$col--background-html: $col--grey;
$col--background-site-wrapper: $col--white;

// Text colors
$col--type-body: $col--black;

// Selected text
$col--selection-bg: $col--black;
$col--selection-text: $col--white;

// Links
$col--link-text: $col--black;
$col--link-text-hover: $col--black;
$col--link-underline: $col--black;
$col--link-underline-hover: transparent;
$col--link-nav-underline: $col--grey;
$col--link-nav-underline-hover: $col--black;
$col--link-tap-highlight: $col--black-translucent;

// Rulers
$col--ruler: $col--black;

// Hamburger
$col--hamburger: $col--black;


// Background colors
.bgcolor--grey {
  background-color: $col--grey;
}

.bgcolor--white {
  background-color: $col--white;
}

.bgcolor--yellow {
  background-color: $col--yellow;
}

.bgcolor--orange {
  background-color: $col--orange;
}

.bgcolor--red {
  background-color: $col--red;
}

.bgcolor--blue {
  background-color: $col--blue;
}

.bgcolor--teal {
  background-color: $col--teal;
}

.bgcolor--green {
  background-color: $col--green;
}

/*================ Site navigation menu ================*/

/**
 * Type formatting
 */
.site-nav {
  @include type--nav;
}

/**
 * List formatting
 */
.site-nav__list {
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

/**
 * Float list items
 */
 @include media-query($medium-up) {

   .site-nav__list {
     @include clearfix;
   }

  .site-nav__list-item {
    @include m-r-spacer-($spacer-md);
    float: left;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

/**
 * Link formatting
 */
.site-nav a {
  @extend .link-nav;
}

/**
 * Active state
 */
.site-nav__list-item.active a {
  @extend .link-active;
}

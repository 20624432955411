/*================ Layout ================*/

/**
 * Outer margins
 */
.site-inner-wrapper {
  @include p-t-spacer-($spacer-md);
  @include p-x-spacer-($spacer-md);
}

/**
 * Ensure grid structure without content
 */
.grid__item {
  @include media-query($large-up) {
    min-height: 1rem;
  }
}

/**
 * Max widths
 */
.site-inner-wrapper {
  @include media-query($medium-down) {
    max-width: $grid-medium;
    margin-right: auto;
    margin-left: auto;
  }

  @include media-query($xlarge) {
    max-width: $grid-xlarge;
    margin-right: auto;
    margin-left: auto;
  }
}


/**
 * Min height
 */
.site-inner-wrapper {
  min-height: 100vh;
}

.page-content {
  min-height: 40vh;
}

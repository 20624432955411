/*================ Pagination ================*/

/**
 * Spacing
 */
.pagination {
  @include m-y-spacer-($spacer-xl);
}

/**
 * Format type
 */
.pagination__overview {
  @include type--small;
}

.pagination__link {
  @include type--subnav;
}

/**
 * Position links
 */
.pagination__links-wrapper {
  @include clearfix;
}
.pagination__link {
  @include m-r-spacer-($spacer-md);
  float: left;

  &:last-of-type {
    margin-right: 0;
  }
}

/**
 * Format links
 */
.pagination__link a {
  @extend .link-nav;
}

// .pagination {
//   background-color: $col--background-pagination;
// }
//
// /**
//  * Type size
//  */
// .pagination {
//   @include type--pagination;
// }
//
// /**
//  * Full width
//  */
// .pagination {
//   @include full-width;
// }
//
// /**
//  * Inner padding
//  */
// .pagination {
//   @include p-x-spacer-($spacer-md);
//   @include p-t-spacer-($spacer-lg);
//   @include p-b-spacer-($spacer-md);
//
//   @include media-query($large-up) {
//     padding-bottom: 1rem;
//   }
// }
//
// /**
//  * Vertical spacing
//  */
// .pagination {
//   @include m-y-spacer-($spacer-lg);
// }

/*================ Information ================*/

/**
 * Borders
 */
.information-entry {
  @include border-top;
}

/**
 * Inner padding
 */
.information-entry {
  @include p-y-spacer-($spacer-lg);
}

/**
 * Images
 */
.information-entry__images-wrapper {
  @include m-t-spacer-($spacer-md);

  @include media-query($large-up) {
    margin-top: 0;
  }
}

/**
 * Format headings
 */
.information-entry__title {
  @include type--large;
}

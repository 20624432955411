// -----------------------------------------------------------------------------
// This file defines the type styles used within the design system.
// -----------------------------------------------------------------------------

// Body
@mixin type--body {
  @include size--1;
  @include line-height--1;
}

// XLarge text
@mixin type--xlarge {
  @include size--4;
}

// Large text
@mixin type--large {
  @include size--2;
}

// Medium text
@mixin type--medium {
  @include size--1;
}

// Small text
@mixin type--small {
  @include size--0;
}

// Uppercase
@mixin type--uppercase {
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

// Site name
@mixin type--site-name {
  @include line-height--0;
  @include type--uppercase;
  font-weight: 600;
  text-align: center;
}

// Nav links
@mixin type--nav {
  @include size--0;
  @include line-height--3;

  @include media-query($large-up) {
    @include size--1;
    @include line-height--1;
  }
}

// Subnav links
@mixin type--subnav {
  @include size--0;
	@include type--uppercase;

  @include media-query($large-up) {
    @include size--1;
  }
}

// Page title
@mixin type--page-title {
  @include size--2;
  @include line-height--0;
	@include type--uppercase;

  @include media-query($large-up) {
    @include size--5;
  }
}

// Headings
@mixin type--heading {
	@include type--uppercase;
}

// News poster title
@mixin type--poster {
  @include size--3;
  @include line-height--0;
  font-weight: 700;
	letter-spacing: 0;
  text-align: center;
  text-transform: none;

  @media (min-width: 400px) {
    @include size--4;
  }

  @media (min-width: 450px) {
    @include size--5;
  }

  @include media-query($large-up) {
    @include size--7;
  }

  @media (min-width: 1040px) {
    @include size--8;
  }
}

// Homepage
@mixin type--home {
  @include size--1;

  @include media-query($large-up) {
    @include size--2;
  }
}

@mixin type--home-large {
  @include size--2;

  @include media-query($medium-up) {
    @include size--4;
  }

  @include media-query($large-up) {
    @include size--5;
  }
}

/*================ News loop ================*/

/**
 * Full width
 */
.news-entry {
  @include full-width;
}

/**
 * Larger base font-size
 */
.news-entry {
  @include media-query($large-up) {
    @include type--large;
  }
}

/**
 * Arched border
 */
.news-entry__inner-wrapper {
  @include arches;
}

/**
 * Spacing
 */
.news-entry {
  @include p-t-spacer-($spacer-lg);
}

/**
 * Redactor
 */
.news-entry {

  figure {
    margin-bottom: 1.2em;
  }

  p,
  ul {

    @include media-query($large-up) {
      width: percentage(6 / 8);
    }
  }
}

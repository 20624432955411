/*================ Page header ================*/

/**
 * Vertical spacing
 */
.page-header {
  @include p-b-spacer-($spacer-md);

  @include media-query($large-up) {
    @include p-b-spacer-($spacer-lg);
  }
}

/**
 * Border
 */
.page-header {
  @include media-query($medium-down) {
    @include border-top;
    @include p-t-spacer-($spacer-lg);
  }
}

/**
 * Page title formatting
 */
.page-header__title {
  @include type--page-title;
}

/**
 * Subnav instruction
 */
.page-header__instruction {
  @include type--small;
}

/**
 * Format links
 */
.page-header__subnav-link {
  @include type--subnav;
}

.page-header__subnav-link {
  margin-bottom: 1.2em; /* line-height = 1.2 */
}

.page-header__filter-list {
  @include clearfix;
  list-style-type: none;
  padding-left: 0;

  .page-header__subnav-link {
    @include m-r-spacer-($spacer-md);
    float: left;

    &:last-of-type {
      margin-right: 0;
    }

    &.active a {
      @extend .link-active;
    }
  }
}

.page-header__subnav-link a {
  @extend .link-nav;
}

// -----------------------------------------------------------------------------
// This file defines the fonts used within the type styles.
// -----------------------------------------------------------------------------

@font-face {
  font-family: 'FoundersGrotesk';
  src: url('../fonts/FoundersGroteskWeb-Medium.eot');
  src: url('../fonts/FoundersGroteskWeb-Medium.eot?#iefix') format('embedded-opentype'),
       url('../fonts/FoundersGroteskWeb-Medium.woff2') format('woff2'),
       url('../fonts/FoundersGroteskWeb-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'FoundersGrotesk';
  src: url('../fonts/FoundersGroteskWeb-Semibold.eot');
  src: url('../fonts/FoundersGroteskWeb-Semibold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/FoundersGroteskWeb-Semibold.woff2') format('woff2'),
       url('../fonts/FoundersGroteskWeb-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'FoundersGrotesk';
  src: url('../fonts/FoundersGroteskWeb-Bold.eot');
  src: url('../fonts/FoundersGroteskWeb-Bold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/FoundersGroteskWeb-Bold.woff2') format('woff2'),
       url('../fonts/FoundersGroteskWeb-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

// 1. Non-webfont to emulate FOUT before fonts are loaded
// 2. Use webfont once it’s loaded
// https://www.bramstein.com/writing/web-font-loading-patterns.html

@mixin font--body {
  font-family: sans-serif; /* 1 */
  font-weight: 500;

  @at-root .fonts-loaded & {
    font-family: 'FoundersGrotesk', sans-serif;
  }
}

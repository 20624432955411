/*================ About matrix ================*/

/**
 * Multiply images
 */
.multiply-images .image {
	mix-blend-mode: multiply;
}

/**
 * Full width blocks
 */
.about-subject__block {
	@include full-width;
}

/**
 * Set padding on white bg blocks for tablet
 */
.about-subject__block.bgcolor--white {
	@include media-query($medium) {
		padding-right: 0;
		padding-left: 0;
	}

	@include media-query($xlarge) {
		padding-right: 0;
		padding-left: 0;
	}
}

/**
 * Inner padding
 */
.about-subject__block {
	@include p-y-spacer-($spacer-lg);
}

/**
 * Remove spacing between blocks of the same colour
 */
.about-subject__block.bgcolor--white + .about-subject__block.bgcolor--white,
.about-subject__block.bgcolor--grey + .about-subject__block.bgcolor--grey,
.about-subject__block.bgcolor--yellow + .about-subject__block.bgcolor--yellow,
.about-subject__block.bgcolor--orange + .about-subject__block.bgcolor--orange,
.about-subject__block.bgcolor--red + .about-subject__block.bgcolor--red,
.about-subject__block.bgcolor--blue + .about-subject__block.bgcolor--blue,
.about-subject__block.bgcolor--teal + .about-subject__block.bgcolor--teal,
.about-subject__block.bgcolor--green + .about-subject__block.bgcolor--green {
	margin-top: -$spacer-lg;
	padding-top: 0;
}

/**
 * Spacing below text
 */
.about-subject__block-text {
	@extend .image;
}

/**
 * Map height
 */
.map-block__map {
	height: 21.875rem;

	@include media-query($large-up) {
		height: 80vh;
	}
}

/**
 * Make maps behave like images
 */
.map-block__map-wrapper {
	@extend .image;
}

.map-block__caption {
	@extend .image__caption;
}

/**
 * Recommendations
 */
.entries-block__entry {
	@extend .image;
	margin-top: 1.2em; // base line-height = 1.2
}

.entries-block__entry-title {
	margin-bottom: 0;
}

/**
 * Horizontal rule
 */
.horizontal-rule {
	@include border-top;
}

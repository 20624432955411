/*================ Transtions ================*/

/**
 * Define initial load transition
 */
.new-session .content-wrapper,
.new-session .site-header {
  opacity: 0;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
  animation-duration: 0.5s;
}

/**
 * Define between pages transition
 */
.fade-out .content-wrapper {
  animation-name: fadeOut !important;
  animation-fill-mode: forwards;
  animation-delay: 0;
  animation-duration: 0.5s;
}

.fade-in .content-wrapper {
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 0;
  animation-duration: 0.5s;
}

// -----------------------------------------------------------------------------
// This file defines the font sizes used within the design system.
// -----------------------------------------------------------------------------

// Font sizes
$fs-s0: 1rem;       // 16px
$fs-s1: 1.3125rem;  // 21px
$fs-s2: 1.75rem;    // 28px
$fs-s3: 2rem;       // 32px
$fs-s4: 2.625rem;   // 42px
$fs-s5: 3.5rem;     // 56px
$fs-s6: 4.5rem;     // 72px
$fs-s7: 6rem;       // 96px
$fs-s8: 7.5rem;     // 120px

@mixin size--0 {
  font-size: $fs-s0;
}

@mixin size--1 {
  font-size: $fs-s1;
}

@mixin size--2 {
  font-size: $fs-s2;
}

@mixin size--3 {
  font-size: $fs-s3;
}

@mixin size--4 {
  font-size: $fs-s4;
}

@mixin size--5 {
  font-size: $fs-s5;
}

@mixin size--6 {
  font-size: $fs-s6;
}

@mixin size--7 {
  font-size: $fs-s7;
}

@mixin size--8 {
  font-size: $fs-s8;
}

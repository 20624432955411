/*================ Homepage ================*/

/**
 * Vertical spacing
 */
.home-news {
	@include p-b-spacer-($spacer-lg);

	@include media-query($large-up) {
		padding-bottom: calc(#{$spacer-xl} - #{$spacer-md});
	}
}

.home-news__primary-entry {
	@include media-query($large-up) {
		@include m-b-spacer-($spacer-lg);
	}
}

.home-news__secondary-entries-wrapper {
	@include media-query($medium-down) {
		@include m-b-spacer-($spacer-lg);
	}
}

.home-news__secondary-entry {
	@include media-query($large-up) {
		@include m-b-spacer-($spacer-md);
	}
}

.home-info {
	@include p-t-spacer-($spacer-lg);
	@include p-b-spacer-($spacer-lg);

	@include media-query($large-up) {
		@include p-b-spacer-($spacer-xl);
	}
}

.home-about, {
	@include p-b-spacer-($spacer-lg);

	@include media-query($large-up) {
		@include p-b-spacer-($spacer-xl);
	}
}

.home-about__image {
	@include p-b-spacer-($spacer-md);

	@include media-query($large-up) {
		@include p-b-spacer-($spacer-lg);
	}
}

/**
 * Borders
 */
.home-info,
.home-about {
	@include border-top;
}

/**
 * Remove full width from news entries
 */
.home-news__secondary-entry .news-entry,
.home-news__secondary-entry .news-poster {
	@include media-query($large-up) {
		margin-right: 0;
		margin-left: 0;
	}
}

/**
 * Format secondary news entries
 */
.home-news__secondary-entry .news-entry,
.home-news__secondary-entry .news-poster {
	@include type--medium;
	@include p-t-spacer-($spacer-lg);

	@include media-query($large-up) {
		@include p-b-spacer-($spacer-lg);
	}

	.grid__item {
		float: none;
		left: auto;
		width: 100%;
	}

	p {
		width: 100%;
	}
}

.home-news__secondary-entry .news-entry__inner-wrapper,
.home-news__secondary-entry .news-poster__inner-wrapper {
	@include media-query($large-up) {
		background: none;
		padding-bottom: 0;
	}
}


.home-news__secondary-entry .news-poster .news-poster__title {
	@include type--xlarge;

	@include media-query($large-up) {
		@include m-b-spacer-($spacer-xl);
		@include p-t-spacer-($spacer-md);
	}

	@include media-query($xlarge-up) {
		@include m-x-spacer-($spacer-md);
	}
}

/**
 * Format type
 */
.home-block__title,
.home-block__cta {
	@include type--home;
}

.home-information__text,
.home-about__text {
	@include type--home-large;
	margin-top: -0.4375rem;
}

.content-wrapper .home-news__secondary-entry .news-poster .news-poster__title {
	@include media-query($large) {
		@include size--3;
	}
}

/**
 * Format about block
 */
.home-about__image {
	@include full-width;
	padding-right: 0;
	padding-left: 0;
}


/**
 * Format information block links
 */
.home-information__text a {
	@extend .link-no-underline;
}

/*================ Header ================*/

/**
 * Vertical spacing
 */
.site-header {
	@include m-b-spacer-($spacer-md);

	@include media-query($medium-up) {
		@include m-b-spacer-($spacer-lg);
	}

	@include media-query($large-up) {
		@include m-b-spacer-($spacer-xl);
	}
}

.site-header .site-nav {
	@include m-t-spacer-($spacer-md);
}

/**
 * Site name formatting
 */
.site-header .site-name {
	@include type--site-name;
	margin-bottom: 0;
}

/**
 * Increase width
 */
.site-header__site-name-wrapper {
	margin-right: -0.2em;
	margin-left: -0.15em;
}

/**
 * Site name image replacement
 */
.site-header .site-name__link {
	background-image: url('../img/vpe.svg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 0 0.111111111111111em;
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
	display: block;
	width: 100%;
	height: 2rem;

	@include media-query($large-up) {
		height: 5.25rem;
	}
}

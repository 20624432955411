/*================ Arch border ================*/

@mixin arch-border($arches) {
  background-image: url('../img/border.svg');
  background-size: calc( (100% - (#{$grid-gutter} * (#{$arches} - 1))) / #{$arches} );
  background-repeat: space no-repeat;
  background-position-y: calc(100% + 2px);
}

@mixin arches {
  @include arch-border(2);
  @include p-b-spacer-($spacer-xl);

  @media screen and (min-width: 400px) and (max-width: 989px) {
    @include arch-border(3);
  }

  @include media-query($large-up) {
    @include arch-border(6);
  }

  @include media-query($xlarge-up) {
    @include p-b-spacer-($spacer-xxl);
  }
}

//-----------------------------------------------------------------------------
// Mixins specific to this website
//-----------------------------------------------------------------------------

// Full width
@mixin full-width {
  @include m-x-spacer-(-$spacer-md);
  @include p-x-spacer-($spacer-md);

  @include media-query($medium) {
    margin-right: 0;
    margin-left: 0;
  }

  @include media-query($xlarge) {
    margin-right: 0;
    margin-left: 0;
  }
}

// Top border
@mixin border-top {
  @include full-width;
  border-top: 0.625rem solid $col--ruler;

  @include media-query($medium) {
    padding-right: 0;
    padding-left: 0;
  }

  @include media-query($xlarge) {
    padding-right: 0;
    padding-left: 0;
  }
}

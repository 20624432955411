/*================ News poster ================*/

/**
 * Full width
 */
.news-poster {
  @include full-width;
}

/**
 * Larger base font-size
 */
.news-poster {
  @include media-query($large-up) {
    @include type--large;
  }
}

/**
 * Arched border
 */
.news-poster__inner-wrapper {
  @include arches;
}

/**
 * Inner margins
 */
.news-poster {
  @include p-t-spacer-($spacer-lg);

  @include media-query($large-up) {
    @include p-t-spacer-($spacer-xl);
  }

  @include media-query($xlarge-up) {
    @include p-t-spacer-($spacer-xxl);
  }
}

.news-poster__title {
  @include type--poster;
  @include m-x-spacer-($spacer-sm);
  @include p-y-spacer-($spacer-md);

  @include media-query($large-up) {
    margin-right: 0;
    margin-left: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

/**
 * Additional type formatting
 */
.small-link a {
 @include type--medium;

 .home-news__secondary-entry & {
   @include type--small;
 }
}

.small-caps {
  @include type--medium;
  @include type--uppercase;
  margin-bottom: 0;

  & + p {
     margin-top: 0;
  }

  .home-news__secondary-entry & {
    @include type--small;
  }
}

/*================ Links ================*/

/**
 * Link formatting
 */
a,
a:link,
a:visited,
a:active,
a:focus {
	text-decoration: none;
	background-position: 0 1em;
	background-size: 100% 0.125rem;
	background-repeat: no-repeat;
	background-image: linear-gradient(transparent 50%, $col--link-underline 50%);
	color: $col--link-text;
}

a:hover {
	background-image: linear-gradient(transparent 50%, $col--link-underline-hover 50%);
	color: $col--link-text-hover;
}

/**
 * Add click effect
 */
a {
	position: relative;
	top: 0;

	&:active {
		top: 0.0625rem;
	}
}

/**
 * Customise mobile tap highlight color
 */
a {
	-webkit-tap-highlight-color: $col--link-tap-highlight;
}

/**
 * Remove underline
 */
.link-no-underline {
	&,
	&:link,
	&:visited,
	&:hover,
	&:active,
	&:focus {
		background: none;
	}
}

/**
 * Nav links
 */
.link-nav {

	&,
	&:link,
	&:visited {
		background-image: linear-gradient(transparent 50%, $col--link-nav-underline 50%);
	}

	&:hover,
	&:active,
	&:focus {
		background-image: linear-gradient(transparent 50%, $col--link-nav-underline-hover 50%);
	}
}

/**
 * Active state
 */
.link-active {
	background-image: linear-gradient(transparent 50%, $col--link-nav-underline-hover 50%);
}

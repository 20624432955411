// -----------------------------------------------------------------------------
// This file defines the colors used within the design system.
// -----------------------------------------------------------------------------

// Core variables (color-focused)
$col--black: #000000;
$col--white: #FFFFFF;
$col--grey: #D2CDC7;
$col--yellow: #F7B445;
$col--orange: #FC8D58;
$col--red: #F56150;
$col--blue: #4A90E2;
$col--teal: #41ACB9;
$col--green: #70B257;
$col--black-translucent: rgba(0, 0, 0, 0.5);
